import React from "react"
import Layout from "../../components/Layout"
import { SEO } from "../../components/seo"

export default function MorenoCV() {
  return (
    <Layout>
      <div className="cv-page">
        <h5>
          2002-2005 Apprendistato di selvicoltore presso l’azienda forestale di
          Ascona
        </h5>
        <h5>2006 Maturità professionale e scuola reclute</h5>
        <h5>
          2007-2009 Selvicoltore presso Claudio Gropengiesser di Ronco s. Ascona
        </h5>
        <h5>
          2009-2012 Scuola universitaria professionale della svizzera italiana
          (SUPSI). Bachelor in architettura
        </h5>
        <h5>2012-2015 Architetto presso Studio Modena di Bellinzona</h5>
        <h5>
          2013-2021 Attività indipendente di architetto, selvicoltore e
          viticoltore
        </h5>
        <h5>2019 Costituzione a suo nome dell’azienda agricola 5117/1/8</h5>
        <h5>2022 Costizutione della MW Architettura Sagl</h5>
      </div>
    </Layout>
  )
}

export const Head = () => <SEO />
